/* eslint-disable no-unused-expressions */
import './public-path';

const isThere = (selector: string) => document.querySelector(selector) !== null;

if (isThere('.text-post-processing')) {
  import(/* webpackChunkName: "text-post-processing" */ './text-post-processing');
}

if (isThere('.o-dealer:not(.o-dealer-bc__opener)')) {
  if (isThere('.o-dealer.m-dealer-map')) {
    import(/* webpackChunkName: "o-dealer-new" */ '../../components_dealer/o-dealer-new');
  } else {
    import(/* webpackChunkName: "o-dealer" */ '../../components_dealer/o-dealer');
  }
}

// Load if there's a link to open the commponent inside a modal
if (isThere('.o-dealer-bc__opener')) {
  if (isThere('.o-dealer-bc__opener-old')) {
    import(/* webpackChunkName: "o-dealer-bc" */ '../../components_dealer/o-dealer-bc');
  } else {
    import(/* webpackChunkName: "o-dealer-bc-new" */ '../../components_dealer/o-dealer-bc-new');
  }
}

if (isThere('.o-dealer_searchteaser')) {
  import(/* webpackChunkName: "o-dealer_searchteaser" */ '../../components_dealer/o-dealer_searchteaser');
}

if (isThere('.m-shopping-cart')) {
  import(/* webpackChunkName: "m-shopping-cart" */ '../../components_bi/m-shopping-cart/m-shopping-cart');
}

if (isThere('.m-user-actions')) {
  import(/* webpackChunkName: "m-user-actions" */ '../../components_bi/m-user-actions/m-user-actions');
}

if (isThere('.o-product-comparison')) {
  import(/* webpackChunkName: "o-product-comparison" */ '../../components_acu/o-product-comparison/o-product-comparison');
}

if (isThere('.category-filters')) {
  import(/* webpackChunkName: "category-filters" */ '../../components_acu/m-category-filters/m-category-filters');
}

if (isThere('.category-compare-overlay')) {
  import(/* webpackChunkName: "category-compare-overlay" */ '../../components_acu/m-category-compare-overlay/m-category-compare-overlay');
}

if (isThere('.o-category-grid-tiles')) {
  import(/* webpackChunkName: "o-category-grid-tiles" */ '../../components_acu/o-category-grid-tiles/o-category-grid-tiles-ts');
}

if (isThere('.category-grid-tile__compare')) {
  import(/* webpackChunkName: "m-category-grid-tile__compare" */ '../../components_acu/m-compare-popover/m-compare-popover');
}

if (isThere('.m-category-slider')) {
  import(/* webpackChunkName: "m-category-slider" */ '../../components_acu/m-category-slider/m-category-slider');
}

if (isThere('.o-technical_data')) {
  import(/* webpackChunkName: "o-technical_data" */ '../../components_bi/o-technical_data');
}

if (isThere('.m-page-reload-notification')) {
  import(/* webpackChunkName: "m-page-reload-notification" */ '../../components_acu/m-page-reload-notification/m-page-reload-notification');
}

if (isThere('.m-upload-dz')) {
  import(/* webpackChunkName: "m-upload-dz" */ '../../components_bi/m-upload-dz/m-upload-dz');
}

if (isThere('.m-dealer-map')) {
  import(/* webpackChunkName: "m-dealer-tabs" */ '../../components_dealer/m-dealer-tabs/m-dealer-tabs');
}

if (isThere('.m-event-module__teaser')) {
  import(/* webpackChunkName: "m-event-module__teaser" */ '../../components_bi/m-event-module');
}
